module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"en","siteUrl":"http://localhost:8000/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"detection":{"order":["querystring","cookie","navigator"],"caches":["cookie"]}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_RuVHNdsR1wgDRPBq3jtnPW51B4eF4DKydXlDEZzzjmU","apiHost":"https://d72oek6l9i5rt.cloudfront.net","head":true,"isEnabledDevMode":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"d117e234-e3f4-4005-add7-fab8fad6cd39","enableDuringDevelop":false,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
